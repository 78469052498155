@import "./variables";

@keyframes popup {
  0% {
    transform: scale(1.0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1.0);
  }
}

@keyframes popupFoxy {
  0% {
    transform: scale(1.0);
  }
  50% {
    transform: scale(1.1);
    color: $foxy;
  }
  100% {
    transform: scale(1.0);
  }
}

@keyframes popupBackgroundFoxy {
  0% {
    transform: scale(1.0);
  }
  50% {
    transform: scale(1.1);
    background-color: $foxy;
  }
  100% {
    transform: scale(1.0);
  }
}

@keyframes scaleUpZero {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.05);
  }
  70% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1.0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes glitching {
  0% {
    transform: translate3d(-10px,0,0) scale3d(-1,-1,1);
    clip-path: polygon(0 20%,100% 20%,100% 21%,0 21%);
  }
  2% {
    clip-path: polygon(0 33%,100% 33%,100% 33%,0 33%);
  }
  4% {
    clip-path: polygon(0 44%,100% 44%,100% 44%,0 44%);
  }
  5% {
    clip-path: polygon(0 50%,100% 50%,100% 20%,0 20%);
  }
  6% {
    clip-path: polygon(0 70%,100% 70%,100% 70%,0 70%);
  }
  7% {
    clip-path: polygon(0 80%,100% 80%,100% 80%,0 80%);
  }
  8% {
    clip-path: polygon(0 50%,100% 50%,100% 55%,0 55%);
  }
  9% {
    clip-path: polygon(0 70%,100% 70%,100% 80%,0 80%);
  }
  9.9% {
    transform: translate3d(-10px,0,0) scale3d(-1,-1,1);
  }
  10%, 100% {
    transform: translateZ(0) scaleX(1);
    clip-path: polygon(0 0,100% 0,100% 100%,0 100%);
  }
}


