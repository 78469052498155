@import "../../styles/global_styles/_variables";
@import "../../styles/global_styles/_mixins";
@import "../../styles/global_styles/_animations";

.contentBar {
  position: relative;
  flex-grow: 1;
  flex-basis: 30em;
  color: $blue;
  padding-left: 1.8em;
  padding-bottom: 2em;

  .heading {
    letter-spacing: 0.13em;
    background: linear-gradient(90deg, $white, $foxy);

    @include text-shadow(0.07em, 0.1em);
    @include init-font(1.5em, 700);

    padding: 0.9em 0;
  }

  .reactIconWrapper {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0.5em 1em;
    width: 3em;

    img {
      animation: rotate 5s infinite linear;
    }
  }
}