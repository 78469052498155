@import '../../styles/global_styles/variables';
@import '../../styles/global_styles/mixins';
@import '../../styles/global_styles/animations';
@import '../../styles/global_styles/media';

.totalWrapper {
  //font-size is in #root with rem, which is changed by media queries
  position: relative;
  width: 100%;
  background-color: $white;

  @include flex-box($flex-box-row-top...);
  @include box-shadow(2px, 8px);
}