@import "../../styles/global_styles/_variables";
@import "../../styles/global_styles/_mixins";
@import "../../styles/global_styles/_animations";

.asideBar {
  position: relative;
  //width: 17em;
  flex-basis: 17em;
  min-height: 1100px; //for the starting loading empty page
  color: $white;
  background-color: $blue;
  padding: 1.5em;
  user-select: text;
  cursor: default;

  @include flex-box($flex-box-col-top...);

  flex-wrap: nowrap;

  .hero-name {  //:)
    @include init-font(1.3em, 700);
    @include text-shadow(0.12em, 0.15em);

    width: 100%;  //it will take all the raw of the container in flex
    color: $foxy;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-align: center;
  }
}