@import "../../styles/global_styles/_variables";
@import "../../styles/global_styles/_mixins";

.graphBlock {
  height: 0.7em;
  background-color: $blueDark;

  .score {
    width: 0;
    height: 100%;
    background-color: $white;
    @include transition(all, 0.5s);
  }
}