@import "../../styles/global_styles/_variables";
@import "../../styles/global_styles/_mixins";

.graph-section {
  max-width: 17em;
  padding: 0.28em 0;

  .subHeading {
    display: block;
    margin-bottom: 0.3em;
  }
}